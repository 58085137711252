import type { LineItemModel } from "@repo/api/models";

export enum ActionKind {
	GENERATE_OFFER = "GENERATE_OFFER",
	OVERWRITE_OFFER = "OVERWRITE_OFFER",
	RESET_OFFER_STATE = "RESET_OFFER_STATE",
	OVERWRITTEN_OFFER = "OVERWRITTEN_OFFER",
	GENERATED_NEW_OFFER = "GENERATED_NEW_OFFER",
	ERROR_FETCHING = "ERROR_FETCHING",
	PRICE_DIFFERENCE = "PRICE_DIFFERENCE",
	PRICE_UPDATED = "PRICE_UPDATED",
	VAT_BY_COMMUNITY = "VAT_BY_COMMUNITY",
	VAT_DIFFERENCE = "VAT_DIFFERENCE",
	VAT_UPDATED = "VAT_UPDATED",
	VAT_SELECTED = "VAT_SELECTED",
	VAT_RESET = "VAT_RESET",
	VAT_FORCE_CHANGE = "VAT_FORCE_CHANGE",
}

export interface SuccessfulCreated {
	newOfferID: string;
	newOfferNumber: string;
	newSfdcOfferLink: string;
}
export interface SuccessfulCreatedApiPayload {
	offer_id: string;
	offer_number: string;
	sfdc_offer_link: string;
}

export interface ActionPayload {
	createOffer?: boolean;
	updateOffer?: boolean;
	overwrittenOffer?: boolean;
	generatedNewOffer?: boolean;
	errorFetching?: boolean;
	priceDifference?: boolean;
	message?: string;
	title?: string;
	responseType?: "error" | "success";
	newOfferID?: string;
	newOfferNumber?: string;
	newSfdcOfferLink?: string;
	lineItems?: Array<LineItemModel>;
	selectedVat?: string;
	vatDifference?: boolean;
	vatForceChange?: boolean;
	vatByCommunity?: boolean;
}

export interface Action {
	type: ActionKind;
	payload?: ActionPayload;
}

export const modalResponseInitialState = {
	createOffer: false,
	updateOffer: false,
	overwrittenOffer: false,
	generatedNewOffer: false,
	errorFetching: false,
	priceDifference: false,
	vatDifference: false,
	vatForceChange: false,
	vatByCommunity: false,
};

export function modalResponseReducer(
	state: ActionPayload,
	action: Action,
): ActionPayload {
	const { type, payload } = action;

	switch (type) {
		case ActionKind.GENERATE_OFFER: {
			return {
				...state,
				...modalResponseInitialState,
				...{ createOffer: true },
			};
		}

		case ActionKind.OVERWRITE_OFFER: {
			return {
				...state,
				...modalResponseInitialState,
				...{ updateOffer: true },
			};
		}

		case ActionKind.RESET_OFFER_STATE: {
			return {
				...state,
				...{
					createOffer: false,
					updateOffer: false,
					errorFetching: false,
					generatedNewOffer: false,
					overwrittenOffer: false,
				},
			};
		}

		case ActionKind.ERROR_FETCHING: {
			return {
				...state,
				...payload,
			};
		}

		case ActionKind.GENERATED_NEW_OFFER: {
			return {
				...state,
				...{
					generatedNewOffer: true,
					createOffer: false,
					updateOffer: false,
					errorFetching: false,
					message:
						"The offer has been successfully generated with the following offer number",
					title: "Offer successfully generated",
					responseType: "success",
					newOfferID: payload?.newOfferID,
					newOfferNumber: payload?.newOfferNumber,
					newSfdcOfferLink: payload?.newSfdcOfferLink,
				},
			};
		}

		case ActionKind.OVERWRITTEN_OFFER: {
			return {
				...state,
				...{
					createOffer: false,
					updateOffer: false,
					errorFetching: false,
					overwrittenOffer: true,
					message: "The offer data has been overwritten successfully.",
					title: "Offer successfully saved",
					responseType: "success",
				},
			};
		}

		case ActionKind.PRICE_DIFFERENCE: {
			return {
				...state,
				...modalResponseInitialState,
				...{ priceDifference: true },
			};
		}

		case ActionKind.PRICE_UPDATED: {
			return {
				...state,
				priceDifference: false,
				lineItems: payload?.lineItems || [],
			};
		}

		case ActionKind.VAT_SELECTED: {
			return {
				...state,
				...{ selectedVat: payload?.selectedVat },
			};
		}

		case ActionKind.VAT_DIFFERENCE: {
			return {
				...state,
				...modalResponseInitialState,
				...{ vatDifference: true },
			};
		}

		case ActionKind.VAT_BY_COMMUNITY: {
			return {
				...state,
				...modalResponseInitialState,
				vatByCommunity: true,
			};
		}

		case ActionKind.VAT_RESET: {
			return {
				...state,
				...modalResponseInitialState,
				...{ selectedVat: undefined },
			};
		}

		case ActionKind.VAT_FORCE_CHANGE: {
			return {
				...state,
				...modalResponseInitialState,
				vatForceChange: true,
			};
		}

		default:
			return state;
	}
}
