// biome-ignore lint/style/useImportType: <explanation>
import React, { useCallback } from "react";
import { Button } from "../button/Button";
import { Dropdown } from "../dropdown";
import { type OnGeneratingProps, onGenerating } from "./onGenerating";

import styles from "./styles.module.css";

export interface GenerateProps extends Omit<OnGeneratingProps, "event"> {
	onCloseButtonClick: () => void;
}

export const updateReason: Record<string, string> = {
	CONSUMPTION_INCREASE: "Consumption Increase",
	CLIENT_REQUEST: "Client request",
	EXTRA_FEE: "Extra fee",
	INCORRECT_VOLTAGE: "Incorrect voltage",
	LAYOUT_CHANGE: "Layout change",
	MORE_PANELS: "More Panels",
	OTHER: "Other",
	PANEL_MODEL_CHANGE: "Panel Model Change",
	SHADOWS: "Shadows",
	WRONG_CONSUMPTION: "Wrong Consumption/Bill according to customer",
	WRONG_ROOF: "Wrong Roof",
};

const optionsKeysUpdateReason = Object.keys(updateReason);

export function Generate({
	onCloseButtonClick,
	mutation,
	mutationCalculation,
	offerData,
	dispatchModal,
	dispatch,
}: GenerateProps) {
	const handleOnSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) =>
			onGenerating({
				dispatch,
				dispatchModal,
				event: event,
				mutation,
				mutationCalculation,
				offerData,
			}),
		[dispatch, dispatchModal, mutation, mutationCalculation, offerData],
	);

	const hasPendindOperation =
		mutation?.isPending || mutationCalculation?.isPending;

	return (
		<div className={styles.layout}>
			<p className={styles.title}>Generate offer as new</p>
			<div className={styles.copyForm}>
				<p className={styles.description}>
					Please enter a Description and Update reason before creating the new
					offer.
				</p>
			</div>
			<form className={styles.form} onSubmit={(event) => handleOnSubmit(event)}>
				<label className={styles.label}>
					<span className={styles.labelText}>Offer description (optional)</span>
					<input
						className={styles.field}
						type="text"
						name="offer-description"
						autoComplete="off"
						disabled={hasPendindOperation}
					/>
				</label>
				<Dropdown disabled={hasPendindOperation}>
					<Dropdown.Content>
						<Dropdown.List>
							{optionsKeysUpdateReason.map((key) => (
								<Dropdown.Item key={key}>{updateReason[key]}</Dropdown.Item>
							))}
						</Dropdown.List>
					</Dropdown.Content>
				</Dropdown>
				<div className={styles.cta}>
					<Button
						isDisabled={hasPendindOperation}
						onClick={onCloseButtonClick}
						variant="white"
						type="button"
					>
						cancel
					</Button>
					<Button
						variant="green"
						type="submit"
						isDisabled={hasPendindOperation}
					>
						generate
					</Button>
					{hasPendindOperation && (
						<span role="alert" aria-busy="true" className={styles.loader} />
					)}
				</div>
			</form>
		</div>
	);
}
