import React from "react";

export const Alert = () => {
	return (
		<svg
			width="80"
			height="80"
			viewBox="0 0 80 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>alert</title>
			<path
				d="M67.3078 39.9991C67.3078 55.0807 55.0817 67.3068 40.0001 67.3068C24.9185 67.3068 12.6924 55.0807 12.6924 39.9991C12.6924 24.9175 24.9185 12.6914 40.0001 12.6914C55.0817 12.6914 67.3078 24.9175 67.3078 39.9991Z"
				stroke="#FED200"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M39.231 29.2305L39.6156 45.3843H40.3848L40.7694 29.2305H39.231Z"
				fill="#FED200"
				stroke="#FED200"
			/>
			<circle cx="40" cy="49.4223" r="1.34615" fill="#FED200" />
		</svg>
	);
};
