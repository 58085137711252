import type { LineItemApiResponseModel, LineItemModel } from "../models";
import { materialTypeConversion } from "./material-type-conversion";

export const validLineItems = (lineItems: Array<LineItemModel>) =>
	lineItems.reduce((acc, lineItem) => {
		if (lineItem.sku) {
			acc.push({
				sku: lineItem.sku,
				name: lineItem.name,
				type: materialTypeConversion(`${lineItem.type}`),
				price_including_taxes: lineItem.sku.startsWith("99")
					? -Math.abs(Number(lineItem.priceIncludingTaxes))
					: Number(lineItem.priceIncludingTaxes),
				price_excluding_taxes: lineItem.sku.startsWith("99")
					? -Math.abs(Number(lineItem.priceExcludingTaxes))
					: Number(lineItem.priceExcludingTaxes),
				cost: Number(lineItem.cost),
				quantity: lineItem.quantity,
				subtotal: lineItem.subtotal,
			} as LineItemApiResponseModel);
		}
		return acc;
	}, [] as LineItemApiResponseModel[]);
