import React from "react";

import { TaxButton } from "./tax-button/TaxButton";
import s from "./styles.module.css";
import { Button } from "../../button/Button";
import { moneyDisplayValue } from "../../../helpers/format";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";

export type LineItemsSummary = {
	marginPercentage: string;
	totalAmountIncludingDiscounts: string;
	totalCost: string;
	totalPriceExcludingTaxes: string;
	totalPriceIncludingTaxes: string;
	vat: string;
};

export type FooterProps = {
	calculationSummary?: LineItemsSummary;
	hasCalculateDisabled?: boolean;
	onChangeTax: (tax: string) => void;
	onClickCalculate: () => void;
};

export const Footer = ({
	calculationSummary,
	hasCalculateDisabled = true,
	onChangeTax,
	onClickCalculate,
}: FooterProps) => {
	const isFetching = useIsFetching();
	const isMutating = useIsMutating();
	const hasSkeletonLoader = isFetching + isMutating > 0;
	const toggleSkeletonLoaderStyle = (styles: string) =>
		`${styles}${hasSkeletonLoader ? " skeleton" : ""}`;

	return (
		<div className={s.layout}>
			<div className={s.wrapper}>
				<div className={`${s.border} ${s.actions}`}>
					{!hasCalculateDisabled && (
						<Button variant="white" type="button" onClick={onClickCalculate}>
							Calculate
						</Button>
					)}
					<span>Totals</span>
				</div>

				<div className={s.border} />
				<div className={s.marginContainer}>
					<span
						className={toggleSkeletonLoaderStyle(
							[s.margin, !hasCalculateDisabled && s.blurred].join(" "),
						)}
					>
						{calculationSummary?.marginPercentage}% Margin
					</span>
				</div>
				<div className={s.taxContainer}>
					<TaxButton
						vat={`${calculationSummary?.vat}`}
						onChangeTax={onChangeTax}
						cssClass={toggleSkeletonLoaderStyle("")}
					/>
				</div>
				<div className={s.border} />
				<div className={s.border} />
				<div className={s.border} />

				<div />

				<div
					className={toggleSkeletonLoaderStyle(
						`${!hasCalculateDisabled && s.blurred}`,
					)}
				>
					{moneyDisplayValue(calculationSummary?.totalCost)} €
				</div>
				<div
					className={toggleSkeletonLoaderStyle(
						`${!hasCalculateDisabled && s.blurred}`,
					)}
				>
					{moneyDisplayValue(calculationSummary?.totalPriceExcludingTaxes)} €
				</div>
				<div
					className={toggleSkeletonLoaderStyle(
						`${!hasCalculateDisabled && s.blurred}`,
					)}
				>
					{moneyDisplayValue(calculationSummary?.totalPriceIncludingTaxes)} €
				</div>
				<div />
				<div
					className={toggleSkeletonLoaderStyle(
						`${!hasCalculateDisabled && s.blurred}`,
					)}
				>
					{moneyDisplayValue(calculationSummary?.totalAmountIncludingDiscounts)}{" "}
					€
				</div>
			</div>
		</div>
	);
};
