import React from "react";

import { Button } from "../button/Button";
import { Alert } from "../icons";
import styles from "./styles.module.css";

export interface VatDifferences {
	onChangeButtonClick: () => void;
	onCloseButtonClick: () => void;
}

export const VatDifferences = ({
	onChangeButtonClick,
	onCloseButtonClick,
}: VatDifferences) => {
	return (
		<div className={styles.layout}>
			<div className={styles.response}>
				<Alert />
				<p className={styles.title}>Tax change</p>
				<p className={styles.description}>
					Are you sure you want to change the tax percentage for this offer?
				</p>
			</div>
			<div className={styles.cta}>
				<Button onClick={onCloseButtonClick} variant="grey" type="button">
					cancel
				</Button>
				<Button onClick={onChangeButtonClick} type="button" variant="yellow">
					confirm change
				</Button>
			</div>
		</div>
	);
};
