import React, { useEffect, useState } from "react";
import { useIsMutating, type UseMutationResult } from "@tanstack/react-query";
import { useTransition, animated, config } from "@react-spring/web";
import { LineItem } from "./LineItem";
import type { LineItemModel, CalculatePayload } from "@repo/api/models";
import type { Materials } from "@repo/api/models";
import { ApiMaterialType, isDiscount } from "@repo/api/materialTypeConversion";

import type { Action as ModalAction } from "@repo/state/modalResponseReducer";
import { ActionKind as ModalActionKind } from "@repo/state/modalResponseReducer";
import type { ActionPayload } from "@repo/state/modalResponseReducer";

import {
	LineItemsActionKind,
	type LineItemsAction,
} from "@repo/state/reducers";
import { toLineItemModel } from "@repo/api/models";

import { getSummary } from "../../helpers/getSummary";
import {
	displayValue,
	moneyDisplayValue,
	numericDisplayValue,
} from "../../helpers/format";
import { Header } from "./Header";
import { Footer, type LineItemsSummary } from "./Footer";
import type { SearchableTerm, SuggestionItem } from "./Autocomplete/types";

import s from "./styles.module.css";

export type LineItemsProps = {
	calculationSummary: LineItemsSummary;
	dispatch: ({ type, payload }: LineItemsAction) => void;
	dispatchModal: ({ type, payload }: ModalAction) => void;
	stateModalResponse: ActionPayload;
	lineItemsList: Array<LineItemModel>;
	suggestionTerms: Array<SuggestionItem>;
	materialsList: Array<Materials.MaterialWithAssociatedMaterialsApiResponseModel>;
	mutation: UseMutationResult<Response, Error, CalculatePayload, unknown>;
	vat: string;
	vatByCommunity: string;
	modalToogle: () => void;
};

export const LineItems = ({
	calculationSummary,
	dispatch,
	dispatchModal,
	lineItemsList,
	suggestionTerms,
	stateModalResponse,
	materialsList,
	mutation,
	vat,
	vatByCommunity,
	modalToogle,
}: LineItemsProps) => {
	const isMutating = useIsMutating();
	const [isDirty, setIsDirty] = useState(false);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (
			(stateModalResponse.vatForceChange ||
				stateModalResponse.vatByCommunity) &&
			stateModalResponse.selectedVat
		) {
			onChangeTax(stateModalResponse.selectedVat);
			dispatchModal({
				type: ModalActionKind.VAT_RESET,
			});
		}
	}, [stateModalResponse]);
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (isDirty) {
			setIsDirty(false);
		}
	}, [isMutating]);

	const onChangeTax = async (selectedTax: string) => {
		const lineItems = lineItemsList.map((lineItem) => {
			if (isDiscount(lineItem)) {
				return lineItem;
			}
			const material = materialsList.find(
				(material) => material.sku === lineItem.sku,
			);
			return {
				...lineItem,
				cost: `${material?.prime_cost}`,
				priceExcludingTaxes: `${material?.price}`,
				priceIncludingTaxes: `${material?.price_with_margin_and_taxes}`,
			};
		});

		const responseCalculation = await mutation.mutateAsync({
			lineItems,
			vat: selectedTax,
		});

		await getSummary({
			responseCalculation,
			dispatchModal,
			dispatch,
		});
	};

	const transitions = useTransition(lineItemsList, {
		config: config.gentle,
		from: {
			opacity: 0,
			width: "100%",
			transform: "translate3d(-25%, 0px, 0px)",
		},
		enter: { opacity: 1, transform: "translate3d(0%, 0px, 0px)" },
		leave: { opacity: 0, height: 0, transform: "translate3d(25%, 0px, 0px)" },
		keys: lineItemsList.map((_, index) => index),
	});

	const handleChangeQuantityClick = (sku: string, quantity: number) => {
		setIsDirty(true);
		dispatch({
			type: LineItemsActionKind.CHANGE_QUANTITY,
			payload: { sku, quantity },
		});
	};

	const handleOnChangeDiscount = (index: number, value: number) => {
		setIsDirty(true);
		dispatch({
			type: LineItemsActionKind.CHANGE_PRICE,
			payload: { index, price: value },
		});
	};

	const handleDeleteClick = (index: number) => {
		setIsDirty(true);
		dispatch({ type: LineItemsActionKind.REMOVE, payload: { index } });
	};

	const handleAddNewItemClick = () => {
		dispatch({ type: LineItemsActionKind.ADD_NEW, payload: {} });
	};

	const handleSelectNewMaterial = (suggestion: SearchableTerm) => {
		setIsDirty(true);
		const selectedMaterial = materialsList.find(
			(material) => material.sku === suggestion.id,
		);
		dispatch({
			type: LineItemsActionKind.ADD_NEW_LINE_ITEM,
			payload: {
				lineItems: toLineItemModel(selectedMaterial),
			},
		});
	};

	const handleCalculateClick = async () => {
		const responseCalculation = await mutation.mutateAsync({
			lineItems: lineItemsList,
			vat: vat,
		});

		await getSummary({
			responseCalculation,
			dispatchModal,
			dispatch,
		});

		setIsDirty(false);
	};

	const handleChangeTax = async (tax: string) => {
		dispatchModal({
			type: ModalActionKind.VAT_SELECTED,
			payload: { selectedVat: tax },
		});

		if (vatByCommunity !== tax) {
			modalToogle();
			dispatchModal({
				type: ModalActionKind.VAT_DIFFERENCE,
			});
		} else {
			dispatchModal({
				type: ModalActionKind.VAT_BY_COMMUNITY,
			});
		}
	};

	const hasCalculateDisabled = mutation.isPending || !isDirty;

	return (
		<div className={s.container}>
			<div className={s.wrapper}>
				<Header />
				<div className={s.content}>
					{transitions((style, lineItem, _, index) => (
						<animated.div style={style}>
							<LineItem
								key={displayValue(lineItem.sku)}
								sku={displayValue(lineItem.sku)}
								index={index}
								name={displayValue(lineItem.name)}
								type={displayValue(lineItem.type)}
								priceIncludingTaxes={moneyDisplayValue(
									Number(lineItem.priceIncludingTaxes),
								)}
								priceExcludingTaxes={moneyDisplayValue(
									Number(lineItem.priceExcludingTaxes),
								)}
								cost={moneyDisplayValue(Number(lineItem.cost))}
								quantity={numericDisplayValue(lineItem.quantity, 0)}
								subtotal={moneyDisplayValue(lineItem.subtotal)}
								hasAdd={index === lineItemsList.length - 1}
								isDiscount={
									lineItem.type?.toLowerCase() === ApiMaterialType.Discount
								}
								suggestionTerms={suggestionTerms}
								onChangeQuantityClick={handleChangeQuantityClick}
								onChangeDiscount={handleOnChangeDiscount}
								onDeleteClick={handleDeleteClick}
								onAddNewItemClick={handleAddNewItemClick}
								onSelectNewMaterial={handleSelectNewMaterial}
							/>
						</animated.div>
					))}
				</div>
				<Footer
					calculationSummary={calculationSummary}
					hasCalculateDisabled={hasCalculateDisabled}
					onChangeTax={handleChangeTax}
					onClickCalculate={handleCalculateClick}
				/>
			</div>
		</div>
	);
};
