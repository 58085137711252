import { isDiscount } from "../helpers/material-type-conversion";
import type { LineItemModel, OfferModel } from "./offer.model";

export interface LineItemApiResponseModel {
	sku: string;
	name: string;
	type: string;
	price_including_taxes: number;
	price_excluding_taxes: number;
	cost: number;
	quantity?: number;
	subtotal?: number;
}

export interface OfferApiResponseModel {
	language: string;
	line_items: LineItemApiResponseModel[];
	offer_id: string;
	offer_number: string;
	opportunity_deal_id: string;
	opportunity_id: string;
	opportunity_name: string;
	original_offer_id: string;
	root_offer_id: string;
	payment_type: string;
	vat: string;
	version_number: number;
	description?: string;
	discount_amount?: number;
	discount_percentage?: number;
	last_modified_by?: string;
	margin_amount?: number;
	margin_excluding_discount_amount?: number;
	margin_excluding_discount_percentage?: number;
	margin_percentage?: string;
	total_amount?: number;
	update_reason?: string;
	vat_by_community: string;
}

function calculateLineItemTotals(lineItems: LineItemApiResponseModel[]) {
	return lineItems.reduce(
		(acc, curr) => {
			const quantity = curr.quantity ?? 1;

			if (!isDiscount(curr)) {
				acc.totalCost += curr.cost * quantity;
				acc.totalPriceExcludingTaxes += curr.price_excluding_taxes * quantity;
			}
			acc.totalPriceIncludingTaxes += curr.price_including_taxes * quantity;

			return acc;
		},
		{
			totalCost: 0,
			totalPriceExcludingTaxes: 0,
			totalPriceIncludingTaxes: 0,
		},
	);
}

/**
 * Transform an API model to a Domain model
 * @param dto API response model
 * @returns Domain model
 */
export function fromDto(dto: OfferApiResponseModel): OfferModel {
	const {
		language,
		line_items: lineItems,
		offer_id: offerId,
		offer_number: offerNumber,
		opportunity_deal_id: opportunityDealId,
		opportunity_id: opportunityId,
		opportunity_name: opportunityName,
		original_offer_id: originalOfferId,
		root_offer_id: rootOfferId,
		payment_type: paymentType,
		vat,
		vat_by_community: vatByCommunity,
		version_number: versionNumber,
		margin_percentage: marginPercentage,
		last_modified_by: lastModifiedBy,
		total_amount: totalAmount = 0,
		discount_amount: discountAmount = 0,
	} = dto;

	// Calculate totals for cost and prices
	const lineItemsTotals = calculateLineItemTotals(lineItems);

	const totalAmountIncludingDiscounts = totalAmount - discountAmount;

	const newLineItem: LineItemModel = {};

	return {
		language,
		offerId,
		offerNumber,
		opportunityDealId,
		opportunityId,
		opportunityName,
		originalOfferId,
		rootOfferId,
		paymentType,
		vat,
		vatByCommunity,
		versionNumber,
		marginPercentage,
		lastModifiedBy,
		totalCost: lineItemsTotals.totalCost.toFixed(2),
		totalPriceExcludingTaxes:
			lineItemsTotals.totalPriceExcludingTaxes.toFixed(2),
		totalPriceIncludingTaxes:
			lineItemsTotals.totalPriceIncludingTaxes.toFixed(2),
		totalAmountIncludingDiscounts: totalAmountIncludingDiscounts.toFixed(2),
		lineItems:
			lineItems.length > 0
				? lineItems.map((lineItem) => {
						const quantity = lineItem.quantity ?? 1;
						return {
							sku: lineItem.sku,
							name: lineItem.name,
							type: lineItem.type,
							priceIncludingTaxes: Number(
								lineItem.price_including_taxes,
							).toFixed(2),
							priceExcludingTaxes: Number(
								lineItem.price_excluding_taxes,
							).toFixed(2),
							cost: Number(lineItem.cost).toFixed(2),
							quantity,
							subtotal: +(lineItem.price_including_taxes * quantity).toFixed(2),
						} as LineItemModel;
					})
				: [newLineItem],
	};
}
