import type {
	CalculatePayload,
	MaterialsApiResponseModel,
	OfferModel,
	OfferMutationModel,
} from "@repo/api/models";
import {
	type Action,
	ActionKind,
	type ActionPayload,
} from "@repo/state/modalResponseReducer";
import type { LineItemsAction } from "@repo/state/reducers";
import { Modal } from "@repo/ui/Modal";
import {
	Generate,
	Overwrite,
	PriceDifferences,
	ResponseStatus,
	VatDifferences,
} from "@repo/ui/OfferModals";
import {
	type UseMutationResult,
	useIsFetching,
	useIsMutating,
} from "@tanstack/react-query";
// biome-ignore lint/style/useImportType: react should be available to include the view
import * as React from "react";

interface ModalManagerProps {
	isShowing: boolean;
	toggle: () => void;
	stateModalResponse: ActionPayload;
	dispatch: React.Dispatch<LineItemsAction>;
	dispatchModalResponse: React.Dispatch<Action>;
	offerToPost: OfferModel;
	offerId: string;
	opportunityName: string;
	mutation: UseMutationResult<Response, Error, OfferMutationModel>;
	mutationUpdate: UseMutationResult<Response, Error, OfferMutationModel>;
	mutationCalculation: UseMutationResult<Response, Error, CalculatePayload>;
	materialsPriceDifferences?: MaterialsApiResponseModel["materials"];
}

export const ModalManager: React.FC<ModalManagerProps> = ({
	isShowing,
	toggle,
	stateModalResponse,
	dispatch,
	dispatchModalResponse,
	offerToPost,
	offerId,
	opportunityName,
	mutation,
	mutationUpdate,
	mutationCalculation,
	materialsPriceDifferences,
}) => {
	const isFetching = useIsFetching();
	const isMutating = useIsMutating();
	const canClose = isFetching + isMutating === 0;
	const {
		createOffer,
		updateOffer,
		overwrittenOffer,
		generatedNewOffer,
		errorFetching,
		message,
		newSfdcOfferLink,
		title,
		responseType,
		priceDifference,
		vatDifference,
	} = stateModalResponse;

	const closeModalOffer = () => {
		if (canClose) {
			toggle();
			dispatchModalResponse({
				type: ActionKind.RESET_OFFER_STATE,
			});
		}
	};

	const closeModalVat = () => {
		if (canClose) {
			toggle();
		}
	};

	const changeModalVat = () => {
		if (canClose) {
			toggle();
			dispatchModalResponse({
				type: ActionKind.VAT_FORCE_CHANGE,
			});
		}
	};

	if (!isShowing) {
		return null;
	}

	return (
		<Modal onCloseButtonClick={closeModalOffer}>
			<>
				{createOffer && (
					<Generate
						dispatch={dispatch}
						dispatchModal={dispatchModalResponse}
						mutation={mutation}
						mutationCalculation={mutationCalculation}
						offerData={offerToPost}
						onCloseButtonClick={closeModalOffer}
					/>
				)}
				{updateOffer && (
					<Overwrite
						mutation={mutationUpdate}
						mutationCalculation={mutationCalculation}
						opportunityName={opportunityName}
						offerData={offerToPost}
						offerId={offerId}
						onCloseButtonClick={closeModalOffer}
						dispatch={dispatch}
						dispatchModal={dispatchModalResponse}
					/>
				)}
				{(generatedNewOffer || overwrittenOffer || errorFetching) && (
					<ResponseStatus
						offerNumber={stateModalResponse.newOfferNumber}
						message={message}
						title={title}
						responseType={responseType}
						sfdcLink={newSfdcOfferLink}
					/>
				)}
				{priceDifference && (
					<PriceDifferences
						offerData={offerToPost}
						materialsPriceDifferences={materialsPriceDifferences}
						onCloseButtonClick={closeModalOffer}
						mutationCalculation={mutationCalculation}
						dispatch={dispatch}
						dispatchModal={dispatchModalResponse}
						modalToggle={toggle}
					/>
				)}
				{vatDifference && (
					<VatDifferences
						onChangeButtonClick={changeModalVat}
						onCloseButtonClick={closeModalVat}
					/>
				)}
			</>
		</Modal>
	);
};
